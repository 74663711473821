<template>
  <div class="card_element flex flex-col p-4 my-8">
    <div class="owner_detail flex items-center ">
      <div class="img_container">
        <img
          src="../../assets/images/user-profil.png"
          alt
        >
      </div>
      <div class="owner_name mx-4">
        Mondilou Dramane
      </div>
    </div>
    <div class="card_details flex flex-col justify-start">
      <div class="card_number my-6">
        {{ formatCard() }}
      </div>
      <div class="card_exp ">
        12/12
      </div>
      <div class="card_solde self-end">
        5000 f
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarsElement',
  props: {
    msg: String
  },
  data () {
    return {
      numero: '4577931806331071'
    }
  },
  methods: {
    formatCard () {
      let tem = this.numero
      return tem.replace(/\B(?=(\d{4})+(?!\d))/g, '  ')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/sass/variables";
.card_element{
  min-width: 20rem;
  width: 30%;
  box-sizing: border-box;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  color: $light_black;
  cursor: pointer;
   transition: 0s linear;
   float: left;
   margin: 0 1.3rem 1.5rem 1.3rem;
    border: 0.5px solid #0000000b;
  &:hover{
    border: 0.5px solid $red;
  }
  .owner_detail{
    .img_container {
          overflow: hidden;
          width: 2.3rem;
          height: 2.3rem;
          border-radius: 50%;
        }
        .owner_name{
          font-size: 1.3rem;
          font-weight: 400;
        }
  }
  .card_details{
    .card_number{
      font-size: 1.3rem;
    }
    .card_exp{
      font-size: .85rem;
    }
    .card_solde{
      color: $light_gray;
    }
  }
}
</style>
